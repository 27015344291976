import styled from 'styled-components'
import { HTMLAttributes } from 'react'
import Link from 'next/link'

type LogoProps = HTMLAttributes<HTMLImageElement>

const LogoImage = styled.img<LogoProps>`
  display: block;
  width: 120px;
`

const Logo = () => (
  <Link href="/">
    <a>
      <LogoImage src="/badge.svg" />
    </a>
  </Link>
)

export default Logo
