import Logo from '../styled/Logo'
import * as S from './styled'
import MainNav from './MainNav'
import { Container } from '../styled'
import { FacebookSquare, Instagram } from '@styled-icons/boxicons-logos'
import { SocialIcons } from './styled'

const Header = () => (
  <S.Header fullWidth>
    <Container vAlign="stretch" row>
      <Logo />
      <MainNav />
      <SocialIcons>
        <a href="https://www.instagram.com/bluerabbitcorsets/">
          <Instagram size={32} />
        </a>
        <a href="https://www.facebook.com/BlueRabbit.Corsets/">
          <FacebookSquare size={32} />
        </a>
      </SocialIcons>
    </Container>
  </S.Header>
)

export default Header
