import styled from 'styled-components'
import { transparentize } from 'polished'
import { Container, ContainerProps } from '../styled'

export const Header = styled(Container).attrs({
  as: 'header',
  vAlign: 'stretch',
})<ContainerProps>`
  background: ${transparentize(0.3, 'purple')};
  align-items: stretch;
  backdrop-filter: blur(5px);
`

export const SocialIcons = styled.div`
  display: flex;
  a {
    display: flex;
    align-items: center;
    color: white;
    transition: 0.2s all;
    padding: 5px;

    &:hover,
    &:active,
    &:focus {
      transform: scale(1.1);
    }
  }
`
