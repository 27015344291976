import { DetailedHTMLProps, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export interface ContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  row?: boolean
  fullWidth?: boolean
  vAlign?: 'center' | 'start' | 'end' | 'stretch'
  narrow?: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;

  ${media.greaterThan('medium')`${css<ContainerProps>`
    flex-direction: ${p => (p.row ? 'row' : 'column')};
    align-items: stretch;
  `}`}

  ${props =>
    !props.fullWidth &&
    css`
      max-width: ${props.narrow ? '60em' : '80em'};
    `}
`
