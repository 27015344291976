import styled from 'styled-components'
import media from 'styled-media-query'

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.2s all;

  ${media.greaterThan('medium')`
    flex-direction: row;
    align-items: stretch;
    height: auto !important;
  `}
`

export const NavToggleButton = styled.button`
  display: block;
  border: 2px solid white;
  background: none;
  color: white;
  padding: 0.5rem 1rem;
  margin: 1rem 0;

  ${media.greaterThan('medium')`display: none;`}
`
