import React, { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import styles from './Nav.module.scss'
import * as S from './styled'
import { NavToggleButton } from './styled'
import { Menu } from '@styled-icons/boxicons-regular'

interface NavLink {
  label: string
  href: string
  as: string
}

const MainNav = () => {
  const [navOriginalHeight, setNavOriginalHeight] = useState(0)
  const [navHeight, setNavHeight] = useState<'auto' | number>('auto')

  const navLinks: NavLink[] = [
    { label: 'Underbusts', href: '/corsets/[type]', as: '/corsets/underbust' },
    { label: 'Overbusts', href: '/corsets/[type]', as: '/corsets/overbust' },
    { label: 'Como Encargar', href: '/como-encargar', as: '/como-encargar' },
    { label: 'Info Útil', href: '/info-util', as: '/info-util' },
  ]

  const toggleNav = () =>
    setNavHeight(height => (height === 0 ? navOriginalHeight : 0))

  const nav = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setNavOriginalHeight(nav.current?.scrollHeight as number)
    setNavHeight(0)
  }, [nav])

  return (
    <>
      <NavToggleButton onClick={toggleNav}>
        <Menu size={16} /> Menu
      </NavToggleButton>
      <S.Nav ref={nav} style={{ height: navHeight }}>
        {navLinks.map(({ label, ...props }) => (
          <Link key={props.as} {...props}>
            <a className={styles.nav__link}>{label}</a>
          </Link>
        ))}
      </S.Nav>
    </>
  )
}

export default MainNav
