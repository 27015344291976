import React from 'react'
import { Footer } from './styled'
import { Container } from '../styled'
import Link from 'next/link'

interface PageFooterProps {
  isHome?: boolean
}

export const PageFooter: React.FC<PageFooterProps> = ({ isHome }) => {
  return (
    <Footer isHome={isHome}>
      <Container row>
        <div>
          &copy; {new Date().getFullYear()} BlueRabbit Corsets &reg; - Since
          2007 -{' '}
          <Link href="/terminos-y-condiciones">
            <a style={{ color: 'mediumpurple' }}>Términos y Condiciones</a>
          </Link>{' '}
          - Buenos Aires, Argentina
        </div>
      </Container>
    </Footer>
  )
}
