import styled, { css } from 'styled-components'
import media from 'styled-media-query'

interface FooterProps {
  isHome?: boolean
}

export const Footer = styled.footer<FooterProps>`
  background: #333;
  color: white;
  padding: 2rem;
  margin-top: 2rem;
  flex-shrink: 0;

  ${p =>
    p.isHome &&
    css`
      align-self: stretch;
    `}

  ${media.greaterThan('huge')`padding: 2rem 0;`}
`
